import $ from 'jquery'
import './slick.min'
import 'lity'
import objectFitImages from 'object-fit-images'

$(function () {
    $('.nav-toggle').click(function () {
        $('.folding-nav').toggleClass('open');
    });
    $('.folding-cover').click(function () {
        $('.folding-nav').toggleClass('open');
    });
    $('.l-side-search__toggle').click(function () {
        $('.l-side-search').toggleClass('open');
    });
})

$(function () {
    $('a[href^="#"]').click(function () {
        var speed = 400;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({scrollTop: position}, speed, 'swing');
        return false;
    });
});


$(".p-home-main-visual__slide").slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 2000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus:false,
    pauseOnHover:false,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: false,
                dots: false,
            }
        }]
});


$(".p-home-main-visual__news__slide").slick({
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
});

$(".p-home-pickup__list").slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
        {
            breakpoint: 780,
            settings: {
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 2,
                centerMode: true,
                infinite: true,
                arrows: false,
            }
        },
        {
            breakpoint: 450,
            settings: {
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 1,
                centerMode: false,
                infinite: true,
                arrows: false,
            }
        }]

});

$(function () {
    $('.p-becc-salc__slide').slick({
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 8000,
    });
});

$(document).ready(function () {
    $('[name="hiroshima"]').click(function () {
        $('.hiroshima').toggle();
    });
    $('[name="shimane"]').click(function () {
        $('.shimane').toggle();
    });
    $('[name="yamaguchi"]').click(function () {
        $('.yamaguchi').toggle();
    });
    $('[name="okayama"]').click(function () {
        $('.okayama').toggle();
    });
    $('[name="ehime"]').click(function () {
        $('.ehime').toggle();
    });
    $('[name="ooita"]').click(function () {
        $('.ooita').toggle();
    });

    $('[name="all"]').click(function () {
        $('.checkBox input').prop('checked', true);
        $('.briefingTable tr').show();
    });

    $('[name="non"]').click(function () {
        $('.checkBox input').prop('checked', false);
        $('.briefingTable tr:nth-of-type(n+2)').hide();
    });

});


objectFitImages();