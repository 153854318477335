<template>
    <div>
        <div class="c-container u-pt-50">
            <h1 class="c-title-type1 u-mb-50">スクールバス時刻表</h1>

            <div v-if="loaded" class="c-box--blue u-mb-50">
                <p class="u-fwb u-fc--bunkyo_blue u-fs-36 u-tac">本日は{{todayStatus}}です</p>
            </div>

            <ul class="l-anchor-link is-3column u-flex-justify-center">
            <li class="l-anchor-link__item">
                <a href="#anc_01"><span>平常運行時刻表</span></a>
            </li>
                <li class="l-anchor-link__item">
                <a href="#anc_02"><span>バス乗り場</span></a>
            </li>
            </ul>

            <h2 class="c-title-type2 u-mb-30">特別運行時刻表</h2>
            <p class=" u-mb-40">特別ダイヤは該当日の時刻表PDFをご確認ください。　<br>※スクールバスは学生と生徒の通学支援専用の車両です。</p>
        </div><!--End .c-container-->
        <div class="p-schoolbus-calendar c-container u-mb-50">

            <div class="u-tac">
                <h4 class="c-title-type4 u-mb-5">{{ currentMoment.format('YYYY年M月') }}</h4>
            </div>
            <ul class="p-schoolbus-calendar__button">
                <li class="p-schoolbus-calendar__button__before"><a @click="goPrevMonth"></a></li>
                <li class="p-schoolbus-calendar__button__next"><a @click="goNextMonth"></a></li>
            </ul>

            <div>
                <table class="p-schoolbus-calendar__table">
                    <tr>
                        <th class="p-schoolbus-calendar__table__th">日</th>
                        <th class="p-schoolbus-calendar__table__th">月</th>
                        <th class="p-schoolbus-calendar__table__th">火</th>
                        <th class="p-schoolbus-calendar__table__th">水</th>
                        <th class="p-schoolbus-calendar__table__th">木</th>
                        <th class="p-schoolbus-calendar__table__th">金</th>
                        <th class="p-schoolbus-calendar__table__th">土</th>
                    </tr>
                    <template v-for="week in calendarData">
                        <tr>
                            <template v-for="day in week">
                                <td class="p-schoolbus-calendar__table__td"
                                    v-bind:class="{other: !day.current , today: day.today}">
                                    <div style="min-height: 90px">
                                        <p class="p-schoolbus-calendar__table__td__date">
                                            {{ day.day }}
                                        </p>
                                        <p style="text-align: center" v-if="day.status==='normal'">
                                            <a style="text-decoration: none; color: #7d7d7d" href="#anc_01">平常運行</a>
                                        </p>
                                        <p v-if="day.status==='off'" class="p-schoolbus-calendar__table__td__tag off">
                                            <a href="" target="_blank">運休</a></p>
                                        <p v-if="day.status==='special'"
                                           class="p-schoolbus-calendar__table__td__tag special">
                                            <a v-bind:href="day.pdf" target="_blank">特別運行</a>
                                        </p>
                                        <p style="text-align: center" v-if="day.status==='undecided'">
                                            <span style="text-decoration: none; color: #7d7d7d">未定</span>
                                        </p>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import axios from 'axios'

    export default {
        props: {
            homeUrl: {
                default: '/',
                type: String,
            }
        },

        data: () => ({
            current: 0,
            diagrams: [],
            status: {
                normal: '平常運行',
                off: '運休',
                special: '特別運行',
                undecided: '未定'
            },
            loaded: false
        }),

        computed: {
            currentMoment() {
                return moment().add(this.current, 'months')
            },

            todayStatus() {
                const calendar = {
                    status: 'normal',
                    date: moment()
                }

                // 土日は運休をセット
                if (moment().weekday() === 0 || moment().weekday() === 6) {
                    calendar.status = 'off'
                }

                // カスタムフィールドの情報を上書き
                this.reflectCustomFields(calendar)

                return this.status[calendar.status]
            },

            calendarData() {
                const numOfMonth = this.currentMoment.endOf('month').date()
                const daysOfMonth = [...Array(numOfMonth).keys()].map(i => ++i)
                const firstWeekDay = this.currentMoment.startOf('month').weekday()

                /* 週ごとの二次元配列を生成 */
                // 初日を取得
                let calendarDate = moment().add(this.current, 'months').startOf('month').subtract(firstWeekDay, 'days')
                return [...Array(6)].map((empty, weekIndex) =>
                    [...Array(7)].map((empty, dayIndex) => {
                        const i = 7 * weekIndex + dayIndex - firstWeekDay
                        const calendar = {
                            status: 'normal',
                            pdf: '',
                            current: true
                        }

                        // 今日かどうか
                        if (calendarDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                            calendar.today = true
                        }

                        // 土日はデフォルトで運休設定
                        if (dayIndex === 0 || dayIndex === 6) {
                            calendar.status = 'off'
                        }

                        // 先月 or 来月
                        if (i < 0 || daysOfMonth[i] === undefined) {
                            calendar.current = false
                        }

                        calendar.day = calendarDate.format('D')
                        calendar.date = calendarDate

                        // カスタムフィールドの情報を上書き
                        this.reflectCustomFields(calendar)

                        calendarDate.add(1, 'days')

                        return calendar
                    })
                )
            },
        },

        created() {
            axios.get(`${this.homeUrl}/wp-json/bunkyo/v1/diagrams`).then(response => {
                this.diagrams = response.data.diagrams
                this.loaded = true
            })
        },

        methods: {
            goNextMonth() {
                this.current++
            },

            goPrevMonth() {
                this.current--
            },

            reflectCustomFields(calendar) {
                // ステータスを上書き
                this.diagrams.forEach(diagram => {
                    // 未定の場合、終了日の入力がないケースがありうる
                    if (!diagram['end_date']) {
                        if (moment(diagram['start_date'], 'YYYYMMDD').format('YYYY-MM-DD') <= moment(calendar['date'], 'YYYYMMDD').format('YYYY-MM-DD')){
                            if (diagram['type'] === '未定') {
                                calendar['status'] = 'undecided'
                            }
                        }
                    } else if (moment(diagram['start_date'], 'YYYYMMDD').format('YYYY-MM-DD') <= moment(calendar['date'], 'YYYYMMDD').format('YYYY-MM-DD') &&
                        moment(diagram['end_date'], 'YYYYMMDD').format('YYYY-MM-DD') >= moment(calendar['date'], 'YYYYMMDD').format('YYYY-MM-DD')) {
                        if (diagram['type'] === '特別運行') {
                            calendar['status'] = 'special'
                            calendar['pdf'] = diagram['pdf']
                        } else if (diagram['type'] === '運休') {
                            calendar['status'] = 'off'
                        } else if (diagram['type'] === '未定') {
                            calendar['status'] = 'undecided'
                        }
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .p-schoolbus-calendar__button {
        a {
            cursor: pointer;
        }
    }
</style>