import '../sass/style.scss'

import './libs/lib'
import 'babel-polyfill'

import Vue from 'vue'
import Calendar from './components/calendar'

if (document.getElementById('calendar')) {
    new Vue({
        el: '#calendar',
        components: {
            Calendar,
        }
    })
}
